import { graphql, useStaticQuery } from 'gatsby';
import { trim } from 'lodash';
import { PageTitle } from 'helpers/eventTracking';
import { usePetNameReplacerWithDefault } from 'helpers/placeholders/dynamicPetNameHelpers';
import { replacePlaceholdersQuestion } from 'helpers/placeholders/replaceCsPlaceholders';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { Pet } from 'state/formData/petsDetails';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import { Question, QuestionWithOptionalPlaceholder } from 'types/forms';

export type AdditionalQuestionsPageQuestions = {
  petDob: Question;
  dogBreedType: Question;
  mongrelSize: Question;
  pedigreeDogBreedName: QuestionWithOptionalPlaceholder;
  crossBreedDogBreedName: QuestionWithOptionalPlaceholder;
};

type CsAdditionalQuestionsPageQuestions = {
  csPetAboutYourPetMainQuestions: {
    pet_date_of_birth: CsQuestion;
  };
  csPetAboutYourPetDogQuestions: {
    dog_breed_type: CsQuestion;
    pedigree_dog_breed_name: CsQuestionWithPlaceholder;
    cross_breed_dog_breed_name: CsQuestionWithPlaceholder;
    mongrel_size: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetAboutYourPetMainQuestions {
      pet_date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
    csPetAboutYourPetDogQuestions {
      dog_breed_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pedigree_dog_breed_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      cross_breed_dog_breed_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      mongrel_size {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      dog_legal_action {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      dog_complaints {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useAdditionalQuestionsPageQuestions = (
  pet: Pet
): AdditionalQuestionsPageQuestions => {
  const csQuestions = useStaticQuery<CsAdditionalQuestionsPageQuestions>(query);
  const {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
  } = useQuestionProcessor(PageTitle.AdditionalQuestions);

  const petNameReplacer = usePetNameReplacerWithDefault(trim(pet.petName));
  const substitutePetName = replacePlaceholdersQuestion(petNameReplacer);

  return {
    petDob: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_date_of_birth)
    ),
    dogBreedType: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetDogQuestions.dog_breed_type)
    ),
    pedigreeDogBreedName: substitutePetName(
      processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPetDogQuestions.pedigree_dog_breed_name
      )
    ),
    crossBreedDogBreedName: substitutePetName(
      processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPetDogQuestions.cross_breed_dog_breed_name
      )
    ),
    mongrelSize: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetDogQuestions.mongrel_size)
    ),
  };
};

export default useAdditionalQuestionsPageQuestions;
