import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import Layout from 'components/Layout';
import AdditionalQuestionsForm from 'forms/AdditionalQuestionsForm';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsHero } from 'types/contentStack';
import { Heading, StyledImage, Subheading } from './styles';

type AdditionalQuestionsProps = {
  data: {
    csPetAggregators: {
      additional_questions: {
        meta_title: string;
        hero: CsHero;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetAggregators {
      additional_questions {
        meta_title
        hero {
          heading
          subheading
        }
      }
    }
  }
`;

const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({
  data: {
    csPetAggregators: {
      additional_questions: { meta_title, hero },
    },
  },
}) => {
  const quote = useCurrentQuote();
  usePageTracking(meta_title, !!quote.customerInfo);

  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote, {
      state: { shouldNotInvalidateAssumptions: true },
    });
  };

  const aggregatorBranding = useAggregatorBrandDetails(quote.productId);

  const subheading = hero.subheading
    ? replacePlaceholdersPlainText(
        { petNames: quotePlaceholders.petNames },
        {
          ...quote,
          petInfos: quote.petInfos,
        },
        true
      )(hero.subheading)
    : undefined;

  return (
    <LoadQuoteWrapper>
      <Layout metaTitle={meta_title} pageTitle={PageTitle.AdditionalQuestions}>
        <Heading>{hero.heading}</Heading>
        {subheading && (
          <Grid alignLeft>
            <GridItem desktop={9} tabletLandscape={9}>
              <Subheading>{subheading}</Subheading>
            </GridItem>
          </Grid>
        )}
        {aggregatorBranding && aggregatorBranding?.logo && (
          <StyledImage image={aggregatorBranding.logo} />
        )}
        <AdditionalQuestionsForm moveNext={moveNext} />
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default AdditionalQuestions;
