import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const CoverStartDateSubheading = styled.h3`
  ${fonts.headingSmall};
  margin: ${spacing(4)} 0 0;

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(6)} 0 0;
  `};
`;

export const CoverStartDateDescription = styled.p`
  ${fonts.paragraphLarge};
  margin: 0;
`;
