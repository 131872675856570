import { CsPlaceholders } from './replaceCsPlaceholders';

export type coverStartDatePlaceholderData = {
  coverStartDate: string;
};

export const coverStartDatePlaceholder: CsPlaceholders<coverStartDatePlaceholderData> = {
  coverStartDate: {
    getSubstitutionText: (placeholders) => placeholders.coverStartDate,
    isPii: false,
  },
};
