import Image from '@rsa-digital/evo-shared-components/components/Image';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledImage = styled(Image)`
  height: ${spacing(4)};
  width: auto;
  margin-bottom: ${spacing(5)};
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(7)};
  `}
`;

export const Heading = styled.h1`
  ${fonts.headingLarge}
  && {
    color: ${colors.core01};
  }
  margin: 0;
  padding-top: ${spacing(2)};
  padding-bottom: ${spacing(3)};
  ${mediaQuery.tabletPortrait`
    padding-top: 0;
  `}
`;

export const Subheading = styled.p`
  ${fonts.paragraphLarge}
  margin: 0;
  padding-bottom: ${spacing(2)};

  && {
    color: ${colors.core01};
  }
`;
